.imageMain {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	padding: 8px;
	margin-top: 10px;
	border: 1px dashed rgba(145, 158, 171, 0.32);
}

.imageInner {
	z-index: 0;
	width: 100%;
	height: 100%;
	outline: none;
	display: flex;
	border-radius: 50%;
	position: relative;
	align-items: center;
	justify-content: center;
}

.imageInner img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

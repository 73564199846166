.phone-input .form-control {
	width: 100% !important;
}

.phone-input-error .special-label,
.phone-input-error .form-control {
	color: rgb(183, 33, 54) !important;
}

.phone-input .phone-input-dropdown li {
	width: 100%;
	height: 100%;
}

.phone-input-helper-text {
	margin-top: 3px;
	font-weight: 400;
	text-align: left;
	margin-bottom: 0;
	line-height: 1.66;
	margin-left: 14px;
	font-size: 0.75rem;
	margin-right: 14px;
	color: rgb(183, 33, 54);
	font-family: "Inter", sans-serif;
}

.react-tel-input .form-control {
	/* border-color: rgba(0, 0, 0, 0.87); */
	/* Filled  */
	border: 0;
	border-radius: 0;
	background: #fafafa;
	border-radius: 4px;
	padding-top: 26px;
	padding-bottom: 10px;
}

.react-tel-input .form-control:hover {
	background-color: #f7f7f7;
}

.react-tel-input .form-control:focus {
	/* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.53); */
	border: 0;
	/* border-bottom: 1px solid #f5d312; */
	/* Filled  */
	box-shadow: none;
	background-color: #f7f7f7;
}

/* Filled  */
.react-tel-input .selected-flag .flag {
	margin-top: -5px;
}

.react-tel-input .special-label {
	top: 3px;
	left: 8px;
	background: transparent;
	font-size: 12px;
	font-family: "Inter", sans-serif;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.6);
}
